@use 'sass:map';

$breakpoints: (
  'smless': (
    max-width: 640px,
  ),
  'smover': (
    min-width: 641px,
  ),
  'mdless': (
    max-width: 767px,
  ),
  'mdover': (
    min-width: 768px,
  ),
  'lgless': (
    max-width: 1024px,
  ),
  'lgover': (
    min-width: 1025px,
  ),
  'xlless': (
    max-width: 1280px,
  ),
  'xlover': (
    min-width: 1281px,
  ),
  'xxlless': (
    max-width: 1440px,
  ),
  'xxlover': (
    min-width: 1441px,
  ),
  'maxless': (
    max-width: 1920px,
  ),
  'maxover': (
    min-width: 1921px,
  ),
) !default;

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  } @else {
    @error "指定されたブレイクポイントはありません -> #{map-keys($breakpoints)}";
  }
}
