@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
breadcrumb
------------------------------------------------------------*/
.c-page{
  &__breadcrumb{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @include r.mq(mdless){
      margin-bottom: 20px;
    }
    li{
      color: #999;
      &:not(:last-child){
        &::after{
          content: ">";
          display: inline-block;
          margin: 0 10px;
        }
      }
      a{
        color: v.$text-color2;
        text-decoration: underline;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
}
