@charset 'utf-8';

@use '../settings/mixin' as r;
@use '../settings/variable' as v;
@use '../settings/_path' as p;

/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
nav,
section,
time,
mark,
audio,
video {
  font-size: 1em;
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-text-size-adjust: 100%;
  font-size: 1.6rem;
  line-height: 1.75;
}

body,
table,
input,
textarea,
select,
option,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Noto Sans JP', 'メイリオ', 'Meiryo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

table,
input,
textarea,
select,
option {
  line-height: 1.1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a {
  transition: 0.3s ease-in-out;
  text-decoration: none;
}
a:hover {
  opacity: 0.8;
  filter: alpha(opacity=80);
  -ms-filter: 'alpha(opacity=80)';
}
form label {
  cursor: pointer;
}

// Safari用のハックは、Chromeに適用されないようにする
@supports (-webkit-touch-callout: none) {
  body {
    // Safari用のハック
    height: -webkit-fill-available;
  }
}

[v-cloak] {
  visibility: hidden;
}

.l-wrapper {
  position: relative;
  overflow: hidden;
}

.pc {
  @include r.mq(mdless) {
    display: none !important;
  }
}

.lgless {
  @include r.mq(lgover) {
    display: none !important;
  }

  @include r.mq(mdless) {
    display: none !important;
  }
}

.sp {
  @include r.mq(mdover) {
    display: none !important;
  }
}

main {
  padding-top: 104px;

  @include r.mq(xlless) {
    padding-top: 69px;
  }

  @include r.mq(mdless) {
    padding-top: 70px;
  }
}

.inner {
  width: 100%;
  max-width: 1040px;
  margin: 0 auto;
  @media (max-width: 1040px) and (min-width: 768px) {
    padding-right: 2%;
    padding-left: 2%;
  }
  @include r.mq(mdless) {
    padding-right: 20px;
    padding-left: 20px;
  }
}

p {
  line-height: 28px;
}
