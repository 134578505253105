@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
search form
------------------------------------------------------------*/
.c-page {
  &__search {
    position: relative;
    z-index: 1;
    .searchform {
      &__content {
        position: relative;
        #s {
          width: 100%;
          border: 2px v.$border-corlor solid;
          border-radius: 10px;
          font-size: 16px;
          padding: 22px 20px;
          box-sizing: border-box;
          &:hover {
            border-color: rgba(0, 146, 206, 0.5);
          }
          &:focus {
            border-color: v.$base-corlor;
          }
        }
        #searchsubmit {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 90px;
          background: v.$base-corlor;
          border: none;
          border-radius: 5px;
          font-size: 16px;
          font-weight: bold;
          color: v.$text-color3;
          padding: 16px 0;
          cursor: pointer;
          position: absolute;
          top: 5px;
          right: 5px;
          &::before {
            content: '';
            width: 20px;
            height: 20px;
            background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-search-white.svg)
              no-repeat center;
            background-size: cover;
            margin-right: 5px;
          }
        }
      }
    }
    &__tag {
      margin-top: 20px;
      font-size: 16px;
      font-weight: bold;
      color: v.$text-color2;
      display: flex;
      align-items: center;
      &::before {
        content: '';
        width: 16px;
        height: 16px;
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-tag-blue.svg)
          no-repeat center;
        background-size: cover;
        margin-right: 5px;
      }
      &__list {
        margin-top: 13px;
        display: flex;
        flex-wrap: wrap;
        li {
          a {
            color: v.$text-color;
            text-decoration: underline;
            &:hover {
              text-decoration: none;
            }
          }
          &:not(:last-child) {
            &::after {
              content: ',';
              margin-right: 6px;
            }
          }
        }
      }
    }
  }
}
