@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
btn
------------------------------------------------------------*/
.c-btn {
  @include r.mq(mdless) {
    width: 100%;
    height: auto;
    background: v.$base-corlor;
    border-bottom: none;
    border-radius: 5px;
    padding: 20px 0;
    &:first-of-type {
      margin-top: 20px;
    }
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
    &__txt {
      color: v.$text-color3;
      font-weight: bold;
      text-align: center;
      p {
        &:first-child {
          font-size: 12px;
        }
        &:last-child {
          display: inline-block;
          font-size: 16px;
          position: relative;
          &::after {
            content: '';
            width: 16px;
            height: 16px;
            position: absolute;
            top: 50%;
            right: -45px;
            transform: translateY(-50%);
            background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-new-window.svg)
              no-repeat center;
            background-size: cover;
          }
        }
      }
      &--1 {
        p {
          &:last-child {
            &::before {
              content: '';
              width: 20px;
              height: 24px;
              position: absolute;
              top: 50%;
              left: -30px;
              transform: translateY(-50%);
              background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-calculator.svg)
                no-repeat center;
              background-size: cover;
            }
          }
        }
      }
      &--2 {
        p {
          &:last-child {
            &::before {
              content: '';
              width: 24px;
              height: 20px;
              position: absolute;
              top: 50%;
              left: -34px;
              transform: translateY(-50%);
              background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-mail-white.svg)
                no-repeat center;
              background-size: cover;
            }
          }
        }
      }
    }
  }
}
