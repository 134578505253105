@forward './Head';

/*------------------------------------------------------------
for develop
------------------------------------------------------------*/
#__bs_notify__ {
  opacity: 0.3 !important;
  font-size: 5px !important;
  line-height: 100% !important;
  padding: 0 5px 0 5px !important;
  -webkit-border-radius: 0 !important;
  border-radius: 0 !important;
}

.c-dev-title1 {
  background: #000;
  color: #fff;
  line-height: 1;
  padding: 6px 0 6px 15px;
  font-size: 1.6rem;
  margin: 60px 0 20px 0;
}

.c-dev-title2 {
  background: #888;
  color: #fff;
  line-height: 1;
  padding: 6px 0 6px 30px;
  font-size: 1.3rem;
  margin: 50px 0 10px 0;
}

.c-dev-message1 {
  top: 0;
  left: 0;
  background: #f00;
  color: #fff;
  z-index: 9999;
  position: fixed;
  line-height: 1;
  padding: 3px 6px;
  opacity: 0.6;
  font-size: 8px;
}

body {
  font-size: 1.4rem;
}

.dev {
  &-ttl {
    margin-top: 20px;
  }

  &-box {
    margin-top: 10px;
    border: 1px solid;
    padding: 20px;
    width: 50%;
  }
}
