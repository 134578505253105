@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
title
------------------------------------------------------------*/
.c-page {
  &__top {
    width: 100%;
    background: url(/cms/wp-content/themes/wp/assets/images/common/ttl_bg.png)
      no-repeat center;
    background-size: cover;
    padding: 80px 0;
    @include r.mq(mdless) {
      padding: 40px 0;
    }
  }
  &__ttl {
    text-align: center;
    &__main {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 38px;
      @include r.mq(mdless) {
        font-size: 24px;
      }
      &--alignleft {
        text-align: initial;
        margin-bottom: 0;
      }
    }
    &__sub {
      display: block;
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      letter-spacing: 0.2em;
      color: v.$text-color2;
      @include r.mq(mdless) {
        font-size: 12px;
        margin-top: 13px;
      }
    }
    &__txt {
      font-size: 16px;
      padding-bottom: 40px;
      border-bottom: 1px v.$border-corlor solid;
      @include r.mq(mdless) {
        text-align: initial;
      }
    }
  }
  &__content {
    padding: 80px 0;
    @include r.mq(mdless) {
      padding: 40px 0;
    }
  }
  &__maintenance {
    width: 100%;
    background: #fff;
    border: 1px v.$border-corlor solid;
    border-radius: 5px;
    padding: 40px;
    @include r.mq(mdless) {
      padding: 30px;
    }
    p {
      font-size: 16px;
      font-weight: bold;
      text-align: center;
    }
  }
  &__cat {
    &__ttl {
      font-size: 32px;
      font-weight: bold;
      padding-left: 20px;
      border-left: 6px v.$base-corlor solid;
      margin-bottom: 20px;
      @include r.mq(mdless) {
        font-size: 24px;
        padding-left: 15px;
      }
    }
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      @include r.mq(mdless) {
        display: block;
        border: 1px v.$border-corlor solid;
        border-radius: 5px;
      }
      li {
        width: calc(1 / 3 * 100% - 10px);
        height: 92px;
        border: 1px v.$border-corlor solid;
        border-radius: 5px;
        @include r.mq(mdless) {
          width: 100%;
          height: auto;
          border: none;
          border-radius: 0;
        }
        &:not(:last-child) {
          @include r.mq(mdless) {
            border-bottom: 1px v.$border-corlor solid;
          }
        }
        a {
          display: flex;
          height: 100%;
          align-items: center;
          font-size: 14px;
          font-weight: bold;
          color: v.$text-color;
          text-decoration: underline;
          padding: 0 36px 0 20px;
          box-sizing: border-box;
          position: relative;
          @include r.mq(mdless) {
            height: auto;
            padding: 20px 46px 20px 30px;
          }
          &:hover {
            background: rgba(0, 146, 206, 0.2);
            text-decoration: none;
          }
          &::after {
            content: '';
            width: 16px;
            height: 16px;
            background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-arrow-blue.svg)
              no-repeat center;
            background-size: cover;
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}

/*
------------------------------------------------------------*/
