@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
single
------------------------------------------------------------*/
.c-singlepage{
  margin-top: 40px;
  margin-bottom: 80px;
  @include r.mq(mdless){
    margin-top: 30px;
    margin-bottom: 40px;
  }
  &__faq{
    margin-bottom: 40px;
    display: flex;
    span{
      &:first-child{
        width: 48px;
        height: 48px;
        background: #111;
        border-radius: 5px;
        font-family: 'Roboto', sans-serif;
        font-size: 32px;
        font-weight: bold;
        color: v.$text-color3;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 13px;
        margin-right: 32px;
        @include r.mq(mdless){
          width: 30px;
          height: 30px;
          font-size: 20px;
          padding: 0 8px;
          margin-right: 20px;
        }
      }
    }
    &--question{
      align-items: baseline;
      padding-bottom: 40px;
      border-bottom: 1px v.$border-corlor solid;
      margin-bottom: 40px;
      @include r.mq(mdless){
        width: 100vw;
        padding: 0 20px 30px;
        margin: 0 calc(50% - 50vw) 30px;
      }
      span{
        &:first-child{
          background: v.$base-corlor;
        }
      }
    }
    &__txt{
      &--question{
        font-size: 40px;
        font-weight: bold;
        @include r.mq(mdless){
          font-size: 24px;
        }
      }
      &--answer{
        font-size: 16px;
      }
    }
  }
  &__content{
    width: calc(100% - 80px);
    margin-left: auto;
    margin-bottom: 40px;
    @include r.mq(mdless){
      width: calc(100% - 50px);
      margin-left: 50px;
    }
    p{
      font-size: 16px;
      margin-bottom: 40px;
      &::after{
        clear: both;
      }
    }
    a{
      color: v.$text-color2;
      text-decoration: underline;
      &[target="_blank"]{
        display: inline-flex;
        align-items: center;
        &::after{
          content: "";
          width: 16px;
          height: 16px;
          background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-new-window-blue.svg)
          no-repeat center;
          background-size: cover;
          margin: 0 2px;
        }
      }
      &:hover{
        text-decoration: none;
      }
    }
    strong{
      font-weight: bold;
    }
    ol{
      list-style-type: decimal;
      margin-bottom: 40px;
      li{
        &::marker{
          font-weight: bold;
        }
      }
    }
    ul{
      list-style-type: disc;
      margin-bottom: 40px;
      &.annotation{
        list-style-type: "※ ";
        li{
          &::marker{
            color: #111;
            margin-right: 5px;
          }
        }
      }
    }
    li{
      font-size: 16px;
      color: #111;
      margin-left: 24px;
      &::marker{
        color: v.$text-color2;
      }
      &:not(:last-child){
        margin-bottom: 20px;
      }
    }
    h2{
      font-size: 32px;
      font-weight: bold;
      padding-left: 20px;
      border-left: 6px v.$base-corlor solid;
      margin-top: 80px;
      margin-bottom: 40px;
      @include r.mq(mdless){
        font-size: 24px;
        padding-left: 15px;
        margin-bottom: 20px;
      }
    }
    h3{
      font-size: 24px;
      font-weight: bold;
      padding-left: 20px;
      border-left: 6px #999 solid;
      margin-top: 80px;
      margin-bottom: 30px;
      @include r.mq(mdless){
        font-size: 20px;
        padding-left: 15px;
      }
    }
    h4{
      font-size: 20px;
      font-weight: bold;
      color: v.$text-color2;
      margin-top: 80px;
      margin-bottom: 20px;
      @include r.mq(mdless){
        font-size: 16px;
      }
    }
    img{
      &.alignleft{
        display: inline;
        float: left;
        margin: 0 10px 10px 0;
      }
      &.alignright{
        display: inline;
        float: right;
        margin: 0 0 10px 10px;
      }
      &.aligncenter{
        display: block;
        clear: both;
        margin: 0 auto 10px;
      }
    }
  }
  &__term{
    display: flex;
    padding: 40px 0;
    border-top: 1px v.$border-corlor solid;
    border-bottom: 1px v.$border-corlor solid;
    margin-bottom: 80px;
    @include r.mq(mdless){
      display: block;
      width: 100vw;
      padding: 30px 20px;
      margin: 0 calc(50% - 50vw) 40px;
    }
    &__cat{
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 5px;
      margin-right: 20px;
      @include r.mq(mdless){
        display: flex;
        margin-bottom: 20px;
        margin-right: 0;
      }
      li{
        border: 1px v.$base-corlor solid;
        border-radius: 2px;
        a{
          display: block;
          padding: 6px 20px;
          font-weight: bold;
          color: v.$text-color2;
          &:hover{
            background: rgba(0, 146, 206, 0.1);
          }
        }
      }
    }
    &__tag{
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      transform: translateY(3px);
      @include r.mq(mdless){
        display: flex;
        transform: translateY(0);
      }
      &::before{
        content: "";
        width: 16px;
        height: 16px;
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-tag-blue.svg) no-repeat center;
        background-size: cover;
        margin-right: 5px;
      }
      li{
        a{
          color: v.$text-color;
          text-decoration: underline;
          &:hover{
            text-decoration: none;
            opacity: 0.7;
          }
        }
        &:not(:last-child){
          &::after{
            content: ",";
            margin-right: 5px;
          }
        }
      }
    }
  }
}
