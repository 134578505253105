@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

/*------------------------------------------------------------
achive
------------------------------------------------------------*/
.c-achivepage{
  margin-bottom: 80px;
  @include r.mq(mdless){
    margin-bottom: 40px;
  }
  &__ttl{
    font-size: 32px;
    font-weight: bold;
    padding-left: 20px;
    border-left: 6px v.$base-corlor solid;
    margin-bottom: 20px;
    @include r.mq(mdless){
      font-size: 24px;
      padding-left: 9px;
    }
  }
  &__box{
    width: 100%;
    border: 1px v.$border-corlor solid;
    border-radius: 5px;
    padding: 40px;
    margin-bottom: 20px;
    pointer-events: none;
    @include r.mq(mdless){
      padding: 30px;
    }
    &:hover{
      background: rgba(0, 146, 206, 0.2);
    }
    dl{
      pointer-events: initial;
      &:hover{
        .c-achivepage{
          &__faq{
            &__txt{
              &--question{
                text-decoration: none;
              }
            }
          }
        }
      }
    }
    &__anker{
      color: #111;
    }
    &.is-hidden {
      display: none;
    }
  }
  &__all{
    width: 184px;
    border: 1px v.$border-corlor solid;
    border-radius: 5px;
    margin: 0 auto;
    a{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #111;
      padding: 22px 0;
      &::after{
        content: "";
        width: 16px;
        height: 16px;
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-arrow-blue.svg) no-repeat center;
        background-size: cover;
        margin-left: 16px;
      }
      &:hover{
        background: rgba(0, 146, 206, 0.2);
      }
    }
  }
  &__more{
    width: 184px;
    border: 1px v.$border-corlor solid;
    border-radius: 5px;
    margin: 0 auto;
    &__link{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #111;
      padding: 22px 0;
      &::after{
        content: "";
        width: 16px;
        height: 16px;
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-plus.svg) no-repeat center;
        background-size: cover;
        margin-left: 16px;
      }
      &:hover{
        background: rgba(0, 146, 206, 0.2);
      }
    }
  }
  &__faq{
    display: flex;
    span{
      line-height: 28px;
      &:first-child{
        width: 30px;
        height: 30px;
        background: #111;
        border-radius: 5px;
        font-family: 'Roboto', sans-serif;
        font-size: 20px;
        font-weight: bold;
        color: v.$text-color3;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0 8px;
        margin-right: 10px;
      }
    }
    &--question{
      span{
        &:first-child{
          background: v.$base-corlor;
        }
      }
    }
    &--answer{
      margin-top: 20px;
    }
    &__txt{
      &--question{
        font-size: 20px;
        font-weight: bold;
        text-decoration: underline;
        @include r.mq(mdless){
          font-size: 16px;
        }
      }
      &--answer{
        font-size: 16px;
        @include r.mq(mdless){
          font-size: 14px;
        }
      }
    }
  }
  &__cat{
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 20px;
    margin-right: 20px;
    @include r.mq(mdless){
      display: flex;
      margin-right: 0;
    }
    li{
      border: 1px v.$base-corlor solid;
      border-radius: 2px;
      margin-right: 5px;
      @include r.mq(mdless){
        margin-bottom: 5px;
      }
      a{
        display: block;
        padding: 6px 20px;
        background: #FFF;
        font-weight: bold;
        color: v.$text-color2;
        pointer-events: initial;
        &:hover{
          background: rgba(0, 146, 206, 0.1);
        }
      }
    }
  }
  &__tag{
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    transform: translateY(3px);
    margin-top: 20px;
    @include r.mq(mdless){
      display: flex;
      transform: translateY(0);
    }
    &::before{
      content: "";
      width: 16px;
      height: 16px;
      background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-tag-blue.svg) no-repeat center;
      background-size: cover;
      margin-right: 5px;
    }
    li{
      a{
        color: v.$text-color;
        text-decoration: underline;
        pointer-events: initial;
        &:hover{
          text-decoration: none;
        }
      }
      &:not(:last-child){
        &::after{
          content: ",";
          margin-right: 5px;
        }
      }
    }
  }
  &__bottom__search{
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
    border-radius: 5px;
    overflow: hidden;
    padding: 40px;
    margin-bottom: 80px;
    position: relative;
    @include r.mq(mdless){
      padding: 40px 20px;
    }
    &::before{
      content: "";
      width: 100%;
      height: 100%;
      background: v.$base-corlor2;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after{
      content: "";
      width: 100%;
      height: 100%;
      background: url(/cms/wp-content/themes/wp/assets/images/common/ttl_bg.png) no-repeat center;
      background-size: cover;
      background-color: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
.c-search__zero{
  text-align: center;
  font-size: 16px;
  @include r.mq(mdless){
    text-align: initial;
  }
  .c-achivepage__more{
    display: none;
  }
}
