@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.l-footer{
  &__contact{
    width: 100%;
    height: 480px;
    background: url(/cms/wp-content/themes/wp/assets/images/common/footer_contact.jpg) no-repeat center;
    background-size: cover;
    position: relative;
    &::before{
      content: "";
      width: 100%;
      height: 100%;
      background: rgba(17, 17, 17, 0.6);
      position: absolute;
      top: 0;
      left: 0;
    }
    @include r.mq(mdless){
      height: auto;
      padding: 40px 20px;
    }
    &__top{
      height: 240px;
      padding: 80px 0;
      position: relative;
      @include r.mq(mdless){
        height: auto;
        padding: 0;
        margin-bottom: 22px;
      }
      p{
        width: 100%;
        color: v.$text-color3;
        font-weight: bold;
        text-align: center;
        &:first-child{
          font-size: 20px;
          margin-bottom: 18px;
          @include r.mq(mdless){
            font-size: 12px;
            margin-bottom: 10px;
          }
        }
        &:last-child{
          font-size: 32px;
          @include r.mq(mdless){
            font-size: 20px;
          }
        }
      }
    }
    &__bottom{
      height: 240px;
      display: flex;
      justify-content: center;
      position: relative;
      @include r.mq(mdless){
        height: auto;
        display: block;
      }
      &__btn{
        width: 50%;
        height: 100%;
        background: rgba(0, 146, 246, 0.6);
        position: relative;
        @include r.mq(mdless){
          width: 100%;
          height: auto;
          background: v.$base-corlor;
          border-radius: 5px;
        }
        &:first-child{
          border-right: 1px rgba(255, 255, 255, 0.5) solid;
          @include r.mq(mdless){
            border-right: none;
            margin-bottom: 11px;
          }
        }
        &::before{
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          width: 0;
          display: block;
          background: #00B1E2;
          opacity: 0.6;
          transition: all 0.4s;
        }
        a{
          display: block;
          width: 100%;
          height: 100%;
          @include r.mq(mdless){
            width: auto;
            height: auto;
            padding: 20px 0;
          }
        }
        &:hover{
          &::before{
            width: 100%;
          }
        }
      }
      &__txt{
        color: v.$text-color3;
        font-weight: bold;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include r.mq(mdless){
          position: initial;
          transform: translate(0, 0);
        }
        p{
          &:first-child{
            font-size: 16px;
            margin-bottom: 13px;
            @include r.mq(mdless){
              font-size: 12px;
              margin-bottom: 5px;
            }
          }
          &:last-child{
            font-size: 24px;
            position: relative;
            &::after{
              content: "";
              width: 16px;
              height: 16px;
              position: absolute;
              top: 50%;
              right: -67px;
              transform: translateY(-50%);
              background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-new-window.svg) no-repeat center;
              background-size: cover;
            }
            @include r.mq(mdless){
              font-size: 16px;
              display: inline-block;
              &::after{
                right: -45px;
              }
            }
          }
        }
        &--left{
          p{
            &:last-child{
              &::before{
                content: "";
                width: 32px;
                height: 32px;
                position: absolute;
                top: 50%;
                left: -42px;
                transform: translateY(-50%);
                background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-calculator.svg) no-repeat center;
                background-size: cover;
              }
              @include r.mq(mdless){
                &::before{
                  width: 20px;
                  height: 24px;
                  left: -30px;
                }
              }
            }
          }
        }
        &--right{
          p{
            &:last-child{
              &::before{
                content: "";
                width: 32px;
                height: 26px;
                position: absolute;
                top: 50%;
                left: -42px;
                transform: translateY(-50%);
                background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-mail-white.svg) no-repeat center;
                background-size: cover;
              }
              @include r.mq(mdless){
                &::before{
                  width: 24px;
                  height: 20px;
                  left: -34px;
                }
              }
            }
          }
        }
      }
    }
  }
  &__content{
    background: #FFF;
    padding: 80px 0;
    @media (max-width:1040px) {
      padding: 80px 2%;
    }
    @include r.mq(mdless){
      padding: 40px 20px;
    }
    &__ttl{
      text-align: center;
      margin-bottom: 40px;
      @include r.mq(mdless){
        margin-bottom: 20px;
      }
      img{
        margin-bottom: 20px;
      }
      p{
        &:nth-of-type(1){
          font-size: 16px;
          font-weight: bold;
        }
        &:nth-of-type(2){
          @include r.mq(mdless){
            text-align: start;
          }
        }
        &:not(:last-child){
          margin-bottom: 10px;
        }
      }
      a{
        color: v.$text-color2;
        text-decoration: underline;
        position: relative;
        &::after{
          content: '';
          display: inline-block;
          background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-new-window-blue.svg)
            no-repeat center;
          background-size: 16px;
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          margin-left: 10px;
        }
        &:hover{
          text-decoration: none;
        }
      }
    }
    &__address{
      display: flex;
      justify-content: center;
      margin-bottom: 40px;
      @include r.mq(mdless){
        display: block;
        margin-bottom: 20px;
      }
      &__txt{
        width: 480px;
        text-align: center;
        @include r.mq(mdless){
          width: 100%;
          border-bottom: 1px v.$border-corlor solid;
          padding: 20px 0;
        }
        &:first-child{
          padding-right: 40px;
          border-right: 1px v.$border-corlor solid;
          @include r.mq(mdless){
            border-top: 1px v.$border-corlor solid;
            border-right: none;
            padding-right: 0;
          }
        }
        &:last-child{
          padding-left: 40px;
          @include r.mq(mdless){
            padding-left: 0;
          }
        }
        p{
          &:first-child{
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 14px;
          }
        }
      }
    }
    small{
      font-family: 'Roboto', sans-serif;
      font-size: 12px;
      color: #999;
      text-align: center;
    }
  }
  .c-pagetop{
    width: 60px;
    height: 60px;
    background: rgba(153, 153, 153, 0.6);
    border-radius: 5px;
    opacity: 1;
    transition: all 0.4s ease;
    position: fixed;
    bottom: 20px;
    right: 20px;
    text-align: center;
    @include r.mq(mdless){
      width: 40px;
      height: 40px;
      bottom: 10px;
      right: 10px;
    }
    a{
      display: block;
      width: 100%;
      height: 100%;
    }
    &__content{
      width: 100%;
      height: 100%;
      padding: 13px 0;
      @include r.mq(mdless){
        padding: 12px;
      }
    }
    img{
      width: 16px;
      height: 16px;
      margin: 0 auto;
    }
    p{
      font-family: 'Roboto', sans-serif;
      font-size: 10px;
      color: v.$text-color3;
      transform: translateY(-10px);
      @include r.mq(mdless){
        display: none;
      }
    }
    &.hide{
      opacity: 0;
    }
  }
}
