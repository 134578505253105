@use '../settings/variable' as v;
@use '../settings/mixin' as r;
@use '../settings/_path' as p;

.l-header {
  $root: &;
  width: 100%;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;

  //------------------------------------------------------
  &__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }

  //------------------------------------------------------
  &__logo {
    margin-left: 40px;
    padding: 38px 0;

    @include r.mq(xlless) {
      width: 25%;
      margin-left: 2%;
      padding: 20px 0;
    }

    @include r.mq(mdless) {
      width: auto;
      margin-left: 20px;
      padding: 20px 0;
    }

    &__link {
      display: flex;
      align-items: center;
      text-decoration: none;

      img {
        @include r.mq(xlless) {
          width: 45%;
          height: auto;
        }

        @include r.mq(mdless) {
          width: 96px;
          height: 15px;
        }
      }
    }

    &__txt {
      color: v.$text-color2;
      font-size: 2rem;
      margin-left: 20px;
      font-weight: bold;

      @include r.mq(xlless) {
        width: 40%;
        font-size: 1.3rem;
      }

      @include r.mq(mdless) {
        font-size: 1.2rem;
        margin-left: 10px;
        width: auto;
      }
    }
  }

  //------------------------------------------------------
  &__icon {
    padding: 35px 15px 10px;
    position: relative;

    @include r.mq(mdover) {
      display: none !important;
    }

    &__txt {
      font-size: 1rem;
      color: v.$text-color2;
      font-weight: bold;
      width: 100%;
    }

    &--open {
      display: none;

      &:before {
        content: '';
        display: inline-block;
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-menu.svg)
          no-repeat center;
        background-size: 20px;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 17px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &--close {
      display: none;

      &:before {
        content: '';
        display: inline-block;
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-close.svg)
          no-repeat center;
        background-size: 20px;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 17px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    &.js-active {
      display: block;
    }
  }

  //------------------------------------------------------
  &__nav {
    display: flex;
    align-items: center;

    @include r.mq(mdless) {
      position: relative;
    }
  }

  //------------------------------------------------------
  &__box {
    margin-right: 20px;

    @include r.mq(xlless) {
      margin-right: 0;
    }

    @include r.mq(mdless) {
      width: 100%;
      height: calc(100% - 70px);
      position: fixed;
      top: 69px;
      right: -100%;
      z-index: 99;
      background: #fff;
      padding: 0 20px 20px;
      margin-right: 0;
      overflow-y: auto;
      transition: all 0.4s ease;
    }

    &.js-active {
      @include r.mq(mdless) {
        right: 0;
      }
    }
  }

  //------------------------------------------------------
  &__menu {
    display: flex;
    align-items: center;

    @include r.mq(xlless) {
      justify-content: space-between;
    }

    @include r.mq(mdless) {
      display: block;
      width: 100%;
    }
  }

  //------------------------------------------------------
  &__item {
    @include r.mq(mdover) {
      &:hover {
        #{$root}__subbox {
          opacity: 1;
          visibility: visible;
        }
        #{$root}__txt:after {
          transform: scale(1, 1);
        }
      }
    }

    @include r.mq(mdless) {
      border-bottom: 1px solid v.$border-corlor;
    }
  }

  //------------------------------------------------------
  &__txt {
    text-decoration: none;
    color: v.$text-color;
    font-weight: bold;
    padding: 41px 20px;
    cursor: pointer;
    position: relative;

    @include r.mq(xlless) {
      padding: 20px 10px;
      display: inline-block;
    }

    @include r.mq(mdless) {
      display: block;
      padding: 20px 50px 20px 10px;
      text-decoration: underline;
    }

    &:before {
      @include r.mq(mdless) {
        content: '';
        display: inline-block;
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-arrow-blue.svg)
          no-repeat center;
        background-size: 16px;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 5px;
      background: v.$base-corlor;
      transform: scale(0, 1);
      transform-origin: left top;
      transition: transform 0.3s;

      @include r.mq(mdless) {
        display: none;
      }
    }

    @include r.mq(mdover) {
      &:hover {
        opacity: 1;

        &:after {
          transform: scale(1, 1);
        }
      }
    }

    &.js-current {
      &:after {
        transform: scale(1, 1);
      }
    }
  }

  //------------------------------------------------------
  &__target {
    padding-right: 30px;

    &:before {
      content: '';
      display: inline-block;
      background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-new-window-blue.svg)
        no-repeat center;
      background-size: 16px;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }

  //------------------------------------------------------
  &__subclose {
    &:before {
      @include r.mq(mdless) {
        content: '';
        display: inline-block;
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-minus.svg)
          no-repeat center;
        background-size: 16px;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
      }
    }
    &.sub-active {
      &::before {
        @include r.mq(mdless) {
          content: '';
          display: inline-block;
          background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-plus.svg)
            no-repeat center;
          background-size: 16px;
          width: 16px;
          height: 16px;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translateY(-50%);
        }
      }
    }
  }

  //------------------------------------------------------
  &__subbox {
    padding-top: 20px;
    width: calc(100% - 20px);
    position: absolute;
    top: 94px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;

    @media screen and (min-width: 1077px) and (max-width: 1280px) {
      top: 68px;
    }

    @include r.mq(mdless) {
      display: none;
      position: initial;
      transform: inherit;
      opacity: 1;
      visibility: visible;
      transition: inherit;
    }

    &__inner {
      background: v.$base-corlor;
      border-radius: 5px;
      padding: 40px;
      box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);

      @include r.mq(mdless) {
        background: v.$base-corlor2;
        box-shadow: none;
        width: 100%;
        padding: 0;
      }
    }
  }

  //------------------------------------------------------
  &__submenu {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    max-width: 960px;
    margin: -20px auto 0;

    @include r.mq(mdless) {
      margin: 0 0 20px;
    }
  }

  //------------------------------------------------------
  &__subitem {
    width: 33%;
    padding: 0 20px;
    margin-top: 20px;
    position: relative;

    @include r.mq(mdless) {
      width: 100%;
      margin-top: 0;
      padding: 20px 60px 20px 30px;
      border-bottom: 1px solid v.$border-corlor;
    }

    &:last-child {
      @include r.mq(mdless) {
        border-bottom: none;
      }
    }

    &:after {
      content: '';
      display: inline-block;
      background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-arrow.svg)
        no-repeat center;
      background-size: 16px;
      width: 16px;
      height: 16px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);

      @include r.mq(mdless) {
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-arrow-blue.svg)
          no-repeat center;
        background-size: 16px;
        top: 50%;
        left: auto;
        right: 30px;
      }
    }
  }

  //------------------------------------------------------
  &__sublink {
    display: flex;
    align-items: center;
    color: v.$text-color3;
    transition: all 0.3s;

    @include r.mq(mdless) {
      color: v.$text-color;
    }

    @include r.mq(mdover) {
      &:hover {
        opacity: 1;
      }

      #{$root}__subtxt {
        &:hover {
          text-decoration: none;
          opacity: 0.7;
        }
      }
    }
  }

  //------------------------------------------------------
  &__subtxt {
    color: v.$text-color3;
    font-weight: bold;
    display: block;
    position: relative;
    padding-left: 4px;
    transition: all 0.3s;

    @include r.mq(mdless) {
      color: v.$text-color;
    }
  }

  //------------------------------------------------------
  &__btn {
    background: v.$base-corlor;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 0;
      display: block;
      background: #00b1e2;
      transition: all 0.2s;
    }

    @include r.mq(mdover) {
      &:hover {
        opacity: 1;
        &:before {
          width: 100%;
        }
      }
    }

    &__link {
      display: inline-block;
      text-decoration: none;
      padding: 36px 41px 40px 54px;
      position: relative;

      @include r.mq(xlless) {
        padding: 20px 41px 20px 54px;
      }

      @include r.mq(mdless) {
        padding: 35px 10px 10px;
      }

      &:before {
        content: '';
        display: inline-block;
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-calculator.svg)
          no-repeat center;
        background-size: 24px;
        width: 24px;
        height: 24px;
        position: absolute;
        top: 49%;
        left: 20px;
        transform: translateY(-50%);

        @include r.mq(mdless) {
          background-size: 20px;
          width: 16px;
          height: 20px;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      &:after {
        content: '';
        display: inline-block;
        background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-new-window.svg)
          no-repeat center;
        background-size: 16px;
        width: 16px;
        height: 16px;
        position: absolute;
        top: 49%;
        right: 19px;
        transform: translateY(-50%);

        @include r.mq(mdless) {
          display: none;
        }
      }

      @include r.mq(mdover) {
        &:hover {
          opacity: 1;
        }
      }
    }

    &__txt {
      font-size: 1.6rem;
      font-weight: bold;
      color: v.$text-color3;

      @include r.mq(mdless) {
        font-size: 1rem;
        line-height: 1.66;
      }
    }
  }
  &__contact {
    @include r.mq(mdless) {
      width: 100%;
      height: auto;
      background: v.$base-corlor;
      border-bottom: none;
      border-radius: 5px;
      padding: 20px 0;
      &:first-of-type {
        margin-top: 20px;
      }
      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
      &__txt {
        color: v.$text-color3;
        font-weight: bold;
        text-align: center;
        p {
          &:first-child {
            font-size: 12px;
          }
          &:last-child {
            display: inline-block;
            font-size: 16px;
            position: relative;
            &::after {
              content: '';
              width: 16px;
              height: 16px;
              position: absolute;
              top: 50%;
              right: -45px;
              transform: translateY(-50%);
              background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-new-window.svg)
                no-repeat center;
              background-size: cover;
            }
          }
        }
        &--1 {
          p {
            &:last-child {
              &::before {
                content: '';
                width: 20px;
                height: 24px;
                position: absolute;
                top: 50%;
                left: -30px;
                transform: translateY(-50%);
                background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-calculator.svg)
                  no-repeat center;
                background-size: cover;
              }
            }
          }
        }
        &--2 {
          p {
            &:last-child {
              &::before {
                content: '';
                width: 24px;
                height: 20px;
                position: absolute;
                top: 50%;
                left: -34px;
                transform: translateY(-50%);
                background: url(/cms/wp-content/themes/wp/assets/images/common/icon/icon-mail-white.svg)
                  no-repeat center;
                background-size: cover;
              }
            }
          }
        }
      }
    }
  }
}
